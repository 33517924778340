import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import {
  APPGS,
  COMMITTEES,
  CONTACTS,
  NOTES,
  PERSONS,
  PERSONS_ELECTED,
  PERSON_GEO,
  PERSON_INFO,
  PERSON_PARLYBIO,
  PERSON_PARLYDATA,
  PERSON_STAFF,
} from "./constants";

import {
  bulkUploadContacts,
  createPerson,
  createPersonInfo,
  deletePerson,
  getAppgs,
  getCommittees,
  getContactsAll,
  getPerson,
  getPersonBioFromParlyAPI,
  getPersonFromParlyAPI,
  getPersonGeo,
  getPersonInfo,
  getPersonNotes,
  getPersonStaff,
  getPersons,
  getPersonsAll,
  getPersonsElected,
  getPersonsSearch,
  updatePerson,
  updatePersonInfo,
} from "../services/PersonsService";
import { useLists } from "./list";
import { PersonSearchFilters, PersonWithTags } from "../types/Person";
import { useMemo } from "react";
import { PaginatedResponse } from "../types/utils";
import { NotificationService } from "../services/NotificationService";

export function usePersons(
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
  filters: PersonSearchFilters = {},
  ids?: string[],
) {
  return useQuery({
    queryKey: [PERSONS, offset, limit, order, filters, ids],
    queryFn: () => getPersons(offset, limit, order, filters, ids),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function usePersonsAll() {
  return useQuery({
    queryKey: [PERSONS],
    queryFn: () => getPersonsAll(),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function usePersonsElected() {
  return useQuery({
    queryKey: [PERSONS_ELECTED],
    queryFn: () => getPersonsElected(),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useGetContactsAll() {
  return useQuery({
    queryKey: [CONTACTS],
    queryFn: () => getContactsAll(),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function usePersonsSearch(
  offset: number = 0,
  limit: number = 25,
  order: string = "surname",
  q?: string,
) {
  return useQuery({
    queryKey: [PERSONS, offset, limit, order, q],
    queryFn: () => getPersonsSearch(offset, limit, order, q),

    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function usePersonsSearchWithTags(
  offset: number = 0,
  limit: number = 25,
  order: string = "surname",
  q?: string,
) {
  const { data: people, isLoading: peopleLoading } = usePersonsSearch(
    offset,
    limit,
    undefined,
    q,
  );

  const { data: lists, isLoading: listsLoading } = useLists(0, 600);

  /* Alternatively to querying the entirety of tags, we can poll them by the user ids 

    const [lists, setLists] = useState<PaginatedResponse<Tag>>();
    const [listsLoading, setListsLoading] = useState<boolean>(true);
    useEffect(() => {
      if (people) {
        const list_ids = people.results.map((p) => p.id);
        getLists(0, 600, "id", list_ids).then((r) => {
          setLists(r);
          setListsLoading(false);
        });
      }
    }, [people, setLists]);
  */

  const data: PaginatedResponse<PersonWithTags> | undefined = useMemo(() => {
    if (lists && people && people.results) {
      const results: PersonWithTags[] = [];
      people.results.forEach((p) => {
        const item: PersonWithTags = { ...p };
        const personLists = lists.results.filter((tag) => {
          // if (tag.people_UIDs) {
          //   if (tag.people_UIDs.includes(p.id)) {
          return tag;
          //   }
          // }
          // return false;
        });
        item["tags"] = personLists;
        results.push(item);
      });
      return { ...people, results };
    }
  }, [lists, people]);
  const isLoading = peopleLoading && listsLoading;

  return { data, isLoading };
}

export const usePersonsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPerson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS] });
      queryClient.invalidateQueries({ queryKey: [PERSONS] });
      NotificationService.Success("Person created!");
    },
  });
};

export const useGetPerson = (personId: string) => {
  return useQuery({
    queryKey: [PERSONS, personId],
    queryFn: () => getPerson(personId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export function useAppgs(offset: number = 0, limit: number = 25) {
  return useQuery({
    queryKey: [APPGS, offset, limit],
    queryFn: () => getAppgs(offset, limit),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export function useCommittees(offset: number = 0, limit: number = 25) {
  return useQuery({
    queryKey: [COMMITTEES, offset, limit],
    queryFn: () => getCommittees(offset, limit),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export const useUpdatePersonInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePersonInfo,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [PERSON_INFO, data.person_id],
      });
      NotificationService.Success("Person info updated");
    },
  });
};

export const useCreatePersonInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPersonInfo,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [PERSON_INFO, data.person_id],
      });
      NotificationService.Success("Person info updated");
    },
  });
};

export const useGetPersonInfo = (personId: string) => {
  return useQuery({
    queryKey: [PERSON_INFO, personId],
    queryFn: () => getPersonInfo(personId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const useGetPersonNotes = (personId: string) => {
  return useQuery({
    queryKey: [NOTES, personId],
    queryFn: () => getPersonNotes(personId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const useGetPersonStaff = (personId: string) => {
  return useQuery({
    queryKey: [PERSON_STAFF, personId],
    queryFn: () => getPersonStaff(personId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const useGetPersonFromParlyAPI = (personParlyId?: string) => {
  return useQuery({
    queryKey: [PERSON_PARLYDATA, personParlyId],
    queryFn: () => getPersonFromParlyAPI(personParlyId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const usePersonGeo = (constituencyId: number) => {
  return useQuery({
    queryKey: [PERSON_GEO, constituencyId],
    queryFn: () => getPersonGeo(constituencyId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const usePersonBio = (personParlyId: string) => {
  return useQuery({
    queryKey: [PERSON_PARLYBIO, personParlyId],
    queryFn: () => getPersonBioFromParlyAPI(personParlyId),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
};

export const useBulkUploadContacts = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: bulkUploadContacts,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS] });
      NotificationService.Success(data);
    },
  });
};

export const useUpdatePerson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePerson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS] });
      NotificationService.Success("Person updated!");
    },
  });
};

export const useDeletePerson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePerson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS] });
      NotificationService.Success("Person deleted!");
    },
  });
};
