import { DrawerStateFilterType, OrganisationsFilterItem } from ".";
import { Person, PersonEmail } from "../../types/Person";

interface Entity {
  type: string;
  name: string;
  id: string;
  parties?: EntityGroup;
  committees?: EntityGroup;
  cross_party_groups?: EntityGroup;
  opened?: boolean;
}

interface EntityGroup {
  type: string;
  name: string;
  id: string;
  opened?: boolean;
  data?: Entity[];
}

const replaceUnderscoresWithDots = (drawerState: Record<string, boolean>) => {
  const updatedDrawerState: Record<string, boolean> = {};
  for (const key in drawerState) {
    if (drawerState.hasOwnProperty(key)) {
      const newKey = key.replace(/_/g, ".");
      updatedDrawerState[newKey] = drawerState[key];
    }
  }
  return updatedDrawerState;
};

export const replaceDotsWithUnderscores = (id: string) => {
  return id.replace(/\./g, "_");
};

const filterSelectedHouses = (
  drawerState: Record<string, boolean>,
): [string[], Record<string, boolean>] => {
  const houses = Object.keys(drawerState).map((key) => key.split("_")[0]);
  const remainingState = Object.fromEntries(
    Object.entries(drawerState).map(([key, value]) => [
      key.split("_").slice(1).join("_"),
      value,
    ]),
  );
  return [houses, remainingState];
};

export const updateFilter = (
  drawerState: Record<string, boolean>,
  organisationsFilterData: OrganisationsFilterItem[],
) => {
  const filterState: DrawerStateFilterType = {
    houses: [],
    committees: [],
    crossPartyGroups: [],
    parties: [],
    specialAdvisers: [],
  };

  const onlyChecked = Object.fromEntries(
    Object.entries(drawerState).filter(([key, value]) => value === true),
  );
  const [selectedHouses, remainingState] = filterSelectedHouses(onlyChecked);
  const updatedDrawerState = replaceUnderscoresWithDots(remainingState);
  filterState.houses = selectedHouses;
  for (let i = 0; i < organisationsFilterData.length; i++) {
    if (organisationsFilterData[i].cttees) {
      for (let j = 0; j < organisationsFilterData[i].cttees.length; j++) {
        if (updatedDrawerState[organisationsFilterData[i].cttees[j].id]) {
          filterState.committees.push(organisationsFilterData[i].cttees[j].id);
        }
      }
    }
    if (organisationsFilterData[i].appgs) {
      for (let j = 0; j < organisationsFilterData[i].appgs.length; j++) {
        if (updatedDrawerState[organisationsFilterData[i].appgs[j].id]) {
          filterState.crossPartyGroups.push(
            organisationsFilterData[i].appgs[j].id,
          );
        }
      }
    }
    if (organisationsFilterData[i].parties) {
      for (let j = 0; j < organisationsFilterData[i].parties.length; j++) {
        if (updatedDrawerState[organisationsFilterData[i].parties[j].id]) {
          filterState.parties.push(organisationsFilterData[i].parties[j].id);
        }
      }
    }
    if (organisationsFilterData[i].specialAdvisers) {
      for (
        let j = 0;
        j < organisationsFilterData[i].specialAdvisers.length;
        j++
      ) {
        if (
          updatedDrawerState[organisationsFilterData[i].specialAdvisers[j].id]
        ) {
          filterState.specialAdvisers.push(
            organisationsFilterData[i].specialAdvisers[j].id,
          );
        }
      }
    }
  }

  filterState.houses = filterState.houses.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.committees = filterState.committees.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.crossPartyGroups = filterState.crossPartyGroups.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.parties = filterState.parties.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.specialAdvisers = filterState.specialAdvisers.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  return filterState;
};

export const getOrgNameFromKey = (org: string) => {
  if (org === "HoC") {
    return "House of Commons";
  }
  if (org === "HoL") {
    return "House of Lords";
  }
  if (org === "SPADS") {
    return "Special Adviser";
  }
  if (org === "NIASSEMBLY") {
    return "Northern Ireland Assembly";
  }
  if (org === "SCOTPARL") {
    return "Scottish Parliament";
  }
  if (org === "Senedd") {
    return "Senedd";
  } else {
    return "N/A";
  }
};

export const isFromParlyApi = (org?: string) => {
  return org ? ["HoC", "HoL"].includes(org) : false;
};

export const getSelectedEmails = (
  selected: string[],
  filteredPersons?: Person[],
): PersonEmail[] | [] => {
  if (!filteredPersons) return [];
  return filteredPersons
    .filter((person) => selected.includes(person.id))
    .map((person) => {
      return { id: person.id, email_address: person.email_address };
    });
};
