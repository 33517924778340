import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { useAppgs, useCommittees, usePersonsElected } from "../../query/person";
import CounterBox from "../../components/CounterBox";
import AddOrCreateListModal from "../../components/AddOrCreateListModal";
import PersonsTable from "./PersonsTable";
import PersonsDrawer from "./PersonsDrawer";

import { useForm } from "react-hook-form";
import { Appg, Committee, Person } from "../../types/Person";
import { PaginatedResponse } from "../../types/utils";
import { getSelectedEmails, updateFilter } from "./utils";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { useFilteredPersons, useOrganisationsFilterData } from "./hooks";
import { useListsAll } from "../../query/list";
import { ProfileList } from "../../types/List";
import { handlePersonsExport } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { NoteBulkCreateModalContainer } from "../Engagements/NotesModals";
import { AuthContext } from "../../context/AuthContext";
import { isFeatureEnabled } from "../../components/utils";
import { Features } from "../../types/User";

export type OrganisationsFilterItem = {
  house: string;
  id: string;
  cttees: {
    name: string;
    id: string;
  }[];
  appgs: {
    name: string;
    id: string;
  }[];
  parties: {
    name: string;
    id: string;
  }[];
  specialAdvisers: {
    name: string;
    id: string;
  }[];
};
type PersonsContainerProps = {
  initialFilter?: string;
};

const PersonsContainer = ({ initialFilter }: PersonsContainerProps) => {
  const { data: persons, isLoading: personsLoading } = usePersonsElected();
  const { data: committees, isLoading: committeesLoading } = useCommittees(
    0,
    10000,
  );
  const { data: crossPartyGroups, isLoading: crossPartyGroupsLoading } =
    useAppgs(0, 10000);
  const { data: lists, isLoading: listsLoading } = useListsAll();

  return (
    <>
      {personsLoading ||
      committeesLoading ||
      crossPartyGroupsLoading ||
      listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {persons && committees && crossPartyGroups && lists ? (
        <Persons
          persons={persons}
          committees={committees}
          crossPartyGroups={crossPartyGroups}
          lists={lists}
          initialFilter={initialFilter}
        />
      ) : null}
    </>
  );
};

type PersonsProps = {
  persons: PaginatedResponse<Person>;
  committees: PaginatedResponse<Committee>;
  crossPartyGroups: PaginatedResponse<Appg>;
  lists: PaginatedResponse<ProfileList>;
  initialFilter?: string;
};

export type DrawerStateFilterType = {
  houses: string[];
  committees: string[];
  crossPartyGroups: string[];
  parties: string[];
  specialAdvisers: string[];
};

export const Persons = ({
  persons,
  committees,
  crossPartyGroups,
  initialFilter,
}: PersonsProps) => {
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState<string[]>([]);
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);

  const [filterState, setFilterState] = useState<DrawerStateFilterType>({
    houses: [],
    committees: [],
    crossPartyGroups: [],
    parties: [],
    specialAdvisers: [],
  });

  const [filterRefresh, setFilterRefresh] = useState<number>(0);
  const organisationsFilterData = useOrganisationsFilterData(
    persons.results,
    committees.results,
    crossPartyGroups.results,
  );
  const navigate = useNavigate();

  const { control, getValues } = useForm<Record<string, boolean>>();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [action, setAction] = useState<number>(1);
  const [notesOpen, setNotesOpen] = useState<boolean>(false);

  useEffect(() => {
    const newFilterState = updateFilter(getValues(), organisationsFilterData);
    setFilterState(newFilterState);
  }, [filterRefresh, getValues, organisationsFilterData]);

  const personsData = useFilteredPersons(persons.results, filterState);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    switch (action) {
      case 1:
        handlePersonsExport(e, selected);
        break;
      case 2:
        setNotesOpen(true);
        break;
      case 3:
        navigate("/emails/create", {
          state: { personEmails: getSelectedEmails(selected, persons.results) },
        });
        break;
      case 4:
        setListsModalOpen(true);
        break;
    }
  };

  return (
    <Box>
      {persons?.results.length ? (
        <Box
          display="inline-flex"
          flexDirection={"row"}
          sx={{ overflow: "hidden" }}
        >
          <Stack direction="column">
            <Collapse in={drawerOpen} orientation="horizontal">
              <PersonsDrawer
                toggle={setDrawerOpen}
                open={drawerOpen}
                data={organisationsFilterData}
                control={control}
                setFilterRefresh={setFilterRefresh}
              />
            </Collapse>
          </Stack>

          <Stack direction="column">
            <Stack
              direction={"row"}
              py={4}
              borderBottom={1}
              borderColor={"divider"}
              alignItems={"center"}
              pr={4}
              pl={drawerOpen ? 8 : 1}
              minHeight={120}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} spacing={3}>
                {!drawerOpen ? (
                  <Box display={"block"}>
                    <Button
                      variant="contained"
                      onClick={() => setDrawerOpen(!drawerOpen)}
                      startIcon={<KeyboardTabIcon />}
                      endIcon={
                        <CounterBox
                          count={
                            filterState.committees.length +
                            filterState.crossPartyGroups.length +
                            filterState.parties.length +
                            filterState.specialAdvisers.length
                          }
                          disabled={
                            filterState.committees.length +
                              filterState.crossPartyGroups.length +
                              filterState.specialAdvisers.length +
                              filterState.parties.length ===
                            0
                          }
                        />
                      }
                    >
                      People Explorer
                    </Button>
                  </Box>
                ) : null}
                <Typography variant="h1">People</Typography>
              </Stack>
              <Stack direction="row" gap={3} alignItems={"center"}>
                <Typography variant="h3">Actions:</Typography>
                <Select
                  defaultValue={1}
                  onChange={(e) => setAction(e.target.value as number)}
                  disabled={selected.length === 0}
                  sx={{
                    minWidth: 280,
                  }}
                  size="small"
                >
                  <MenuItem value={1}>
                    Export data for {selected.length} selected
                  </MenuItem>
                  <MenuItem value={2}>
                    Add a note for {selected.length} selected
                  </MenuItem>
                  <MenuItem
                    value={3}
                    disabled={!isFeatureEnabled(user, Features.EMAILING)}
                  >
                    Email to {selected.length} selected
                  </MenuItem>
                  <MenuItem value={4}>Add {selected.length} to a List</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  disabled={selected.length === 0}
                  onClick={handleConfirm}
                >
                  Confirm selection
                </Button>
              </Stack>

              {/* <Stack direction="row" gap={3} alignItems={"center"}>
                <Box gap={2} display="flex">
                  <Button
                    variant="contained"
                    disabled={selected.length === 0}
                    startIcon={<DownloadIcon />}
                    onClick={(e) => handlePersonsExport(e, selected)}
                    endIcon={
                      <CounterBox
                        count={selected.length}
                        disabled={selected.length === 0}
                      />
                    }
                  >
                    Export selected to CSV
                  </Button>
                  <Button
                    variant="contained"
                    disabled={selected.length === 0}
                    endIcon={
                      <CounterBox
                        count={selected.length}
                        disabled={selected.length === 0}
                      />
                    }
                    onClick={() => setListsModalOpen(true)}
                    startIcon={
                      <Typography
                        variant="h2"
                        sx={{
                          color: selected.length === 0 ? "grey" : "white",
                        }}
                      >
                        +
                      </Typography>
                    }
                  >
                    Add selected to a list
                  </Button>
                </Box>
              </Stack> */}
            </Stack>

            <PersonsTable
              persons={personsData.results}
              setSelected={setSelected}
              selected={selected}
              drawerOpen={drawerOpen}
              initialFilter={initialFilter}
            />
          </Stack>
        </Box>
      ) : null}
      <AddOrCreateListModal
        people={selected}
        setSelected={setSelected}
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />
      <NoteBulkCreateModalContainer
        personIds={selected}
        open={notesOpen}
        setOpen={setNotesOpen}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default PersonsContainer;
